import { ReactNode, useEffect, useState } from 'react'
import Head from 'next/head'
import type { AppProps as MuiAppProps } from 'next/app'
import type { NextComponentType, NextPageContext } from 'next'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { CacheProvider, EmotionCache } from '@emotion/react'
import { SnackbarProvider } from 'notistack'
import { BaseAppProvider } from '@baseapp-frontend/core'

import GA4 from 'components/GA4'
import createEmotionCache from 'styles/createEmotionCache'
import DefaultLayout from 'layouts/DefaultLayout'
import theme from 'styles/theme'

import 'public/fonts/fonts.css'
import 'public/embedbamboo.css'
import { hotjar } from 'react-hotjar'
import Script from 'next/script'

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

type NextComponentTypeWithLayout<P = Record<string, unknown>> = NextComponentType<
  NextPageContext,
  any,
  P
> & {
  getLayout?(page: ReactNode): ReactNode
}

interface AppProps extends MuiAppProps {
  Component: NextComponentTypeWithLayout
  emotionCache?: EmotionCache
}

export const siteDomain = process.env.TSL_MARKETING_SITE_DOMAIN

const App = (props: AppProps) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout || DefaultLayout

  const [queryClient] = useState(() => new QueryClient())

  useEffect(() => {
    // Accessibility
    const s = document.createElement('script')
    const h = document.querySelector('head') || document.body
    s.src = 'https://acsbapp.com/apps/app/dist/js/app.js'
    s.async = true
    s.onload = function () {
      // eslint-disable-next-line
      // @ts-ignore
      acsbJS.init({
        statementLink: '',
        footerHtml: '',
        hideMobile: false,
        hideTrigger: false,
        disableBgProcess: false,
        language: 'en',
        position: 'right',
        leadColor: '#146FF8',
        triggerColor: '#146FF8',
        triggerRadius: '50%',
        triggerPositionX: 'right',
        triggerPositionY: 'bottom',
        triggerIcon: 'people',
        triggerSize: 'bottom',
        triggerOffsetX: 20,
        triggerOffsetY: 20,
        mobile: {
          triggerSize: 'small',
          triggerPositionX: 'right',
          triggerPositionY: 'bottom',
          triggerOffsetX: 20,
          triggerOffsetY: 20,
          triggerRadius: '20',
        },
      })
    }
    h.appendChild(s)
    hotjar.initialize({ id: 943060, sv: 6 })
  }, [])

  const options = {
    queries: {
      staleTime: 0,
      cacheTime: 5 * 60 * 1000,
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      retryOnMount: false,
    },
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>
        <BaseAppProvider pageProps={pageProps} queryClientOptions={options}>
          <SnackbarProvider maxSnack={3} autoHideDuration={5000}>
            <CacheProvider value={emotionCache}>
              <Head>
                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=5, shrink-to-fit=no"
                />
                <link rel="icon" href="/assets/favicons/favicon.ico" />
                <meta name="robots" content="max-image-preview:large" />
                {/* favicons */}
                <link
                  rel="apple-touch-icon"
                  sizes="57x57"
                  href="/assets/favicons/apple-touch-icon-57x57.png"
                ></link>
                <link
                  rel="apple-touch-icon"
                  sizes="60x60"
                  href="/assets/favicons/apple-touch-icon-60x60.png"
                ></link>
                <link
                  rel="apple-touch-icon"
                  sizes="72x72"
                  href="/assets/favicons/apple-touch-icon-72x72.png"
                ></link>
                <link
                  rel="apple-touch-icon"
                  sizes="76x76"
                  href="/assets/favicons/apple-touch-icon-76x76.png"
                ></link>
                <link
                  rel="apple-touch-icon"
                  sizes="114x114"
                  href="/assets/favicons/apple-touch-icon-114x114.png"
                ></link>
                <link
                  rel="apple-touch-icon"
                  sizes="120x120"
                  href="/assets/favicons/apple-touch-icon-120x120.png"
                ></link>
                <link
                  rel="apple-touch-icon"
                  sizes="144x144"
                  href="/assets/favicons/apple-touch-icon-144x144.png"
                ></link>
                <link
                  rel="apple-touch-icon"
                  sizes="152x152"
                  href="/assets/favicons/apple-touch-icon-152x152.png"
                ></link>
                <link
                  rel="apple-touch-icon"
                  sizes="180x180"
                  href="/assets/favicons/apple-touch-icon-180x180.png"
                ></link>
                <link rel="shortcut icon" href="/assets/favicons/favicon.ico"></link>
                <meta name="apple-mobile-web-app-capable" content="yes"></meta>
                <meta
                  name="apple-mobile-web-app-status-bar-style"
                  content="black-translucent"
                ></meta>
                <meta name="apple-mobile-web-app-title" content="mar-react"></meta>
                <link
                  rel="icon"
                  type="image/png"
                  sizes="32x32"
                  href="/assets/favicons/favicon-32x32.png"
                ></link>
                <link
                  rel="icon"
                  type="image/png"
                  sizes="16x16"
                  href="/assets/favicons/favicon-16x16.png"
                ></link>
                <link rel="shortcut icon" href="/assets/favicons/favicon.ico"></link>
                <meta name="mobile-web-app-capable" content="yes"></meta>
                <meta name="theme-color" content="#fff"></meta>
                <meta name="application-name" content="mar-react"></meta>
                <link
                  rel="apple-touch-startup-image"
                  media="(device-width: 320px) and (device-height: 480px) and (-webkit-device-pixel-ratio: 1)"
                  href="/assets/favicons/apple-touch-startup-image-320x460.png"
                ></link>
                <link
                  rel="apple-touch-startup-image"
                  media="(device-width: 320px) and (device-height: 480px) and (-webkit-device-pixel-ratio: 2)"
                  href="/assets/favicons/apple-touch-startup-image-640x920.png"
                ></link>
                <link
                  rel="apple-touch-startup-image"
                  media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2)"
                  href="/assets/favicons/apple-touch-startup-image-640x1096.png"
                ></link>
                <link
                  rel="apple-touch-startup-image"
                  media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)"
                  href="/assets/favicons/apple-touch-startup-image-750x1294.png"
                ></link>
                <link
                  rel="apple-touch-startup-image"
                  media="(device-width: 414px) and (device-height: 736px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3)"
                  href="/assets/favicons/apple-touch-startup-image-1182x2208.png"
                ></link>
                <link
                  rel="apple-touch-startup-image"
                  media="(device-width: 414px) and (device-height: 736px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3)"
                  href="/assets/favicons/apple-touch-startup-image-1242x2148.png"
                ></link>
                <link
                  rel="apple-touch-startup-image"
                  media="(device-width: 768px) and (device-height: 1024px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 1)"
                  href="/assets/favicons/apple-touch-startup-image-748x1024.png"
                ></link>
                <link
                  rel="apple-touch-startup-image"
                  media="(device-width: 768px) and (device-height: 1024px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 1)"
                  href="/assets/favicons/apple-touch-startup-image-768x1004.png"
                ></link>
                <link
                  rel="apple-touch-startup-image"
                  media="(device-width: 768px) and (device-height: 1024px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2)"
                  href="/assets/favicons/apple-touch-startup-image-1496x2048.png"
                ></link>
                <link
                  rel="apple-touch-startup-image"
                  media="(device-width: 768px) and (device-height: 1024px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2)"
                  href="/assets/favicons/apple-touch-startup-image-1536x2008.png"
                ></link>
                {/* <!-- TODO: Replace "content" with your own --> */}
                {/* <!-- MS, fb & Whatsapp --> */}
                {/* <!-- MS Tile - for Microsoft apps--> */}
                <meta
                  name="msapplication-TileImage"
                  content={`${siteDomain}/assets/logo_ms_app.webp`}
                />
                {/* <!-- fb & Whatsapp --> */}
                {/* <!-- Site Name, Title, and Description to be displayed --> */}
                <meta property="og:site_name" content="The SilverLogic" />
                {/* <!-- No need to change anything here --> */}
                <meta property="og:type" content="website" />
                <meta property="og:image:type" content="image/png" />
                {/* <!-- Size of image. Any size up to 300. Anything above 300 will not work in WhatsApp --> */}
                <meta property="og:image:width" content="300" />
                <meta property="og:image:height" content="300" />
                {/* <!-- Website to visit when clicked in fb or WhatsApp--> */}
                <meta property="og:url" content="https://tsl.io/"></meta>
                {/* <!-- Twitter Card --> */}
                <meta data-react-helmet="true" name="twitter:card" content="summary_large_image" />
                <meta data-react-helmet="true" name="twitter:site" content="@thesilerlogic" />
                <meta
                  data-react-helmet="true"
                  name="twitter:image"
                  content={`${siteDomain}/assets/logo_og.webp`}
                />
                <meta data-react-helmet="true" name="twitter:label1" content="☎️" />
                <meta data-react-helmet="true" name="twitter:data1" content="(561) 569-2366" />
                <meta data-react-helmet="true" name="twitter:label2" content="📧" />
                <meta data-react-helmet="true" name="twitter:data2" content="info@tsl.io" />
              </Head>
              <GA4 />
              <ThemeProvider theme={theme}>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                {getLayout(<Component {...pageProps} />)}
                <Script
                  id="facebook-pixel"
                  strategy="afterInteractive"
                  dangerouslySetInnerHTML={{
                    __html: `
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window,document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '387520982619598'); 
                fbq('track', 'PageView');
              `,
                  }}
                />
              </ThemeProvider>
            </CacheProvider>
          </SnackbarProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </BaseAppProvider>
      </Hydrate>
    </QueryClientProvider>
  )
}

export default App
